import React from 'react';

const GELogo = (props) => {
  return (
        <div id="logo-wrapper">
            <svg id="ge-logo-mark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 288">
                  <circle className="cls-1" cx="143.97" cy="146.59" r="94.49"/>
                  <path className="cls-2" d="M65.35,136.94a10.52,10.52,0,0,0-18,9.41c-3.89,3-6.07,5.73-5.33,7.1,1.16,2.13,8.93.16,17.35-4.41s14.32-10,13.17-12.13C71.86,135.71,69.12,135.81,65.35,136.94Z"/>
                  <path className="cls-2" d="M52.51,154.44A10.52,10.52,0,0,0,68,146.23a74.51,74.51,0,0,1-6.89,4.24A72.1,72.1,0,0,1,52.51,154.44Z"/>
                  <path className="cls-2" d="M76.27,79.88c2.39-1.31,5.23-1.48,7.73-1.63l7.58-.46a26.74,26.74,0,0,0-38.81,31.9l.28-.46c.68-1.18,1.39-2.39,2.76-2.69a4.76,4.76,0,0,1,3.26.85,10,10,0,0,0,1.37.65,4.77,4.77,0,0,0,6.07-3.65,9.3,9.3,0,0,0-.14-2.67c-.05-.4-.11-.8-.15-1.21a3.88,3.88,0,0,1,3.22-4.9,13.19,13.19,0,0,1,7.71.49,26.4,26.4,0,0,1,3.37,1.67,25.28,25.28,0,0,0,3.3,1.63c2,.74,4.81,1,6.53-.69a7.66,7.66,0,0,0-3.6-5.18,15.66,15.66,0,0,0-5.46-1.59c-.73-.13-1.49-.25-2.23-.41-1.63-.36-5.51-1.56-6.35-4.87C72,83.87,74,81.14,76.27,79.88Z"/>
                  <path className="cls-2" d="M78,127.57A26.73,26.73,0,0,0,99.78,85.31a4.43,4.43,0,0,1-.65.49,5.67,5.67,0,0,1-1.89.73c-1.61.32-3.28.15-4.89.49s-3.25,1.42-3.45,3,.95,2.88,2.21,3.74C93.48,95.42,96.54,96,98.58,98a6.21,6.21,0,0,1,1.74,5.83,5.12,5.12,0,0,1-4.55,3.83c-1.83.08-3.5-.93-5.17-1.69a20.43,20.43,0,0,0-11.92-1.5,4.88,4.88,0,0,0-2.87,1.3c-1.22,1.35-.83,3.45-.36,5.21-.87-1.11-2.86-.37-3.32,1a4.92,4.92,0,0,0,.85,4,17.57,17.57,0,0,0,3,3.76A30.73,30.73,0,0,0,67.77,117c-1.45-.24-3.12-.31-4.18.69a4.48,4.48,0,0,0-1.13,2.4,12.34,12.34,0,0,0-.26,2.28A26.61,26.61,0,0,0,78,127.57Z"/>
                  <path className="cls-3" d="M141.09,110.5c0,.16-.25,1.35-.88,3.56.18-1.1-3.29,12.51-3.28,12.4,0,.12,3.43-13.29,3.08-11.72-2.11,7.13-8,23.17-23,47.47-21,33.91-62.47,30.51-62.47,30.51-1.27-.19.77,2.94,2.94,5.88h-.11c-2.39-.23,7.25,10.49,7.25,10.49,55.63-9.13,71.84-75.22,75.17-92.68C140.65,112.88,141.05,110.72,141.09,110.5Z"/>
                  <path className="cls-3" d="M141.09,110.5v0Z"/>
                  <path className="cls-3" d="M136.93,126.46h0Z"/>
                  <path className="cls-2" d="M144,46.55A98.06,98.06,0,1,1,74.63,75.27,97.4,97.4,0,0,1,144,46.55M144,5A139.66,139.66,0,1,0,283.62,144.61,139.65,139.65,0,0,0,144,5Z"/>
                  <path className="cls-4" d="M144,267.62a123,123,0,1,1,123-123A123.16,123.16,0,0,1,144,267.62Zm0-244.84A121.83,121.83,0,1,0,265.79,144.61,122,122,0,0,0,144,22.78Z"/>
                  <path className="cls-4" d="M144,268.22A123.61,123.61,0,1,1,267.58,144.61,123.75,123.75,0,0,1,144,268.22ZM21.55,144.61a122.42,122.42,0,0,0,244.84,0,122.42,122.42,0,0,1-244.84,0ZM144,23.38A121.23,121.23,0,1,0,265.2,144.61,121.36,121.36,0,0,0,144,23.38Z"/>
                  <polygon className="cls-2" points="124.36 13.37 124.36 33.48 90.19 54.77 77.55 61.17 50.25 89.21 36.7 132.04 10.55 127.87 23.33 81.73 64.01 33.48 118.17 13.37 124.36 13.37"/>
                  <path className="cls-3" d="M27.77,110.62a3.79,3.79,0,0,0-1.08,1.16A5.45,5.45,0,0,0,26,113.3,4.1,4.1,0,0,0,26,115a3.93,3.93,0,0,0,.56,1.46,4.1,4.1,0,0,0,1.12,1.16,4.76,4.76,0,0,0,1.6.72,4.38,4.38,0,0,0,1.76.09,4.06,4.06,0,0,0,1.55-.56,4,4,0,0,0,1.2-1.11,4.35,4.35,0,0,0,.73-1.55,4.07,4.07,0,0,0,0-2,4.51,4.51,0,0,0-.81-1.73l-.46,1.79-4.18-1.06L31,104.53l6.63,1.68a11.16,11.16,0,0,1,1.81,2.2A10.86,10.86,0,0,1,40.61,111a10.35,10.35,0,0,1,.46,2.84,10.85,10.85,0,0,1-5.21,9.57,10.8,10.8,0,0,1-8.22,1.19,11.14,11.14,0,0,1-3.86-1.81,10.7,10.7,0,0,1-2.8-3A10.33,10.33,0,0,1,19.56,116a10.13,10.13,0,0,1,.19-4.23A11.39,11.39,0,0,1,20.81,109a12,12,0,0,1,1.27-1.88,8.08,8.08,0,0,1,1.46-1.38ZM24,109.35a4.32,4.32,0,0,0-.52.79,10.1,10.1,0,0,0-.46.93,9.31,9.31,0,0,0-.41,1.23,8.22,8.22,0,0,0-.18,3.11,7.46,7.46,0,0,0,3,5,8.49,8.49,0,0,0,3,1.37,8.27,8.27,0,0,0,3.26.18,7.3,7.3,0,0,0,2.82-1,7.87,7.87,0,0,0,2.19-2,8.18,8.18,0,0,0,1.32-2.83,7.7,7.7,0,0,0,0-4,8.31,8.31,0,0,0-1.9-3.37l-3.92-1-.8,3.17.72.18.61-2.39,3,.76a6.88,6.88,0,0,1,1.58,3,7,7,0,0,1,0,3.48A7.27,7.27,0,0,1,36,118.4a7.07,7.07,0,0,1-2,1.83,7,7,0,0,1-2.56.93,7.34,7.34,0,0,1-2.93-.17,7.57,7.57,0,0,1-2.66-1.23,6.61,6.61,0,0,1-1.82-2,6.38,6.38,0,0,1-.87-2.49,7.34,7.34,0,0,1,.18-2.78,5.48,5.48,0,0,1,.35-1c.14-.31.27-.57.39-.8s.29-.49.43-.71Z"/>
                  <path className="cls-3" d="M30.37,126.92a12.68,12.68,0,0,1-3.21-.41,13,13,0,0,1-4.57-2.13,12.82,12.82,0,0,1-3.3-3.52,12.26,12.26,0,0,1-1.48-9.58,13.46,13.46,0,0,1,1.25-3.3,13.3,13.3,0,0,1,1.5-2.2,10.08,10.08,0,0,1,1.83-1.72l1.49-1,4.19,4.88,1.47-5.81,9.08,2.3.38.36a13.47,13.47,0,0,1,2.13,2.59,13.06,13.06,0,0,1,1.39,3,12.25,12.25,0,0,1,.55,3.37,12.76,12.76,0,0,1-10.78,13A12.32,12.32,0,0,1,30.37,126.92ZM27.94,114a1.73,1.73,0,0,0,0,.64,1.79,1.79,0,0,0,.27.72,2.16,2.16,0,0,0,.58.59,2.78,2.78,0,0,0,.94.42,2.43,2.43,0,0,0,1,.06,2.23,2.23,0,0,0,.81-.29,2.3,2.3,0,0,0,.65-.6,3.38,3.38,0,0,0,.24-.41Z"/>
                  <path className="cls-3" d="M28.34,88.64l3.41-6.22a12.44,12.44,0,0,1,2.21-3,8.52,8.52,0,0,1,2.75-1.81,6.92,6.92,0,0,1,3.13-.48,8.07,8.07,0,0,1,3.35,1,7.12,7.12,0,0,1,2.09,1.64,8.17,8.17,0,0,1,1.11,1.7,6.73,6.73,0,0,1,.52,1.86l7.8-.11L51,90.06l-6,.2,4.57,2.51-3.15,5.75Zm14.84.82a11.09,11.09,0,0,0,.91-2.17l5.73-.2.46-.83-6,.21A5.76,5.76,0,0,0,44,83.28a4.85,4.85,0,0,0-2.3-2.39,5.16,5.16,0,0,0-2.33-.68,5,5,0,0,0-2.19.44,6.42,6.42,0,0,0-2,1.37,10.15,10.15,0,0,0-1.6,2.18L32,87.12l13.71,7.52.38-.71L42,91.68Zm-1.87,1.86L33,86.77l1.21-2.21a7,7,0,0,1,3.19-3.26,4.21,4.21,0,0,1,3.85.3,4.13,4.13,0,0,1,2.3,3.07,7,7,0,0,1-1,4.44Zm-1-3.58.16-.29a3.63,3.63,0,0,0,.5-2,2.3,2.3,0,0,0-3.08-1.69,3.66,3.66,0,0,0-1.42,1.49l-.16.3Z"/>
                  <path className="cls-3" d="M47.16,101.24,25.62,89.43l4.37-8A14.38,14.38,0,0,1,32.58,78,10.5,10.5,0,0,1,36,75.8a9.24,9.24,0,0,1,4-.62,10.08,10.08,0,0,1,4.17,1.29,8.87,8.87,0,0,1,2.67,2.12,10.15,10.15,0,0,1,1.39,2.13c.1.23.18.45.26.68l9.65-.15Z"/>
                  <path className="cls-3" d="M50,56.71l3.38-3.59,20.31,8.45-4.44,4.7-1.82-.77L63.11,70,64,71.79l-4.44,4.7Zm11,14.75L60.19,70l7-7.4,1.52.63.59-.62L53.84,56.18,53,57l7.28,15.05ZM54,57.12l12.47,5.15-6.6,7Zm8.93,6.52-3.57-1.47L61,65.66Z"/>
                  <polygon className="cls-3" points="59.01 79.97 47.55 56.34 50.08 53.66 49.89 53.27 50.29 53.43 52.83 50.75 77.08 60.83 69.69 68.66 67.88 67.89 65.52 70.38 66.4 72.14 59.01 79.97"/>
                  <path className="cls-3" d="M69.32,38.93,73.87,36l9,3.66L78.66,33l5.45-3.45,11,17.37-4.58,2.9-9-3.65,4.22,6.67L80.32,56.3ZM82.1,52.25l-7.62-12,16.21,6.59.68-.43L83,33.18l-.67.43,7.62,12L73.73,39.05l-.67.43,8.36,13.2Z"/>
                  <polygon className="cls-3" points="79.7 59.06 66.56 38.31 69.66 36.35 69.45 36.02 69.89 36.2 73.67 33.8 77.88 35.51 75.9 32.39 84.72 26.8 97.87 47.54 94.77 49.51 94.98 49.84 94.54 49.66 90.73 52.07 86.57 50.38 88.52 53.47 79.7 59.06"/>
                  <path className="cls-3" d="M104.32,42.6,98,23l5.43-1.75a18.44,18.44,0,0,1,5.11-1,10.23,10.23,0,0,1,4.23.67A8.24,8.24,0,0,1,116,23.33a11.33,11.33,0,0,1,2.15,4,11.32,11.32,0,0,1,.59,4.48,8.5,8.5,0,0,1-1.27,3.8,10.59,10.59,0,0,1-3,3,18.38,18.38,0,0,1-4.71,2.21Zm2.18-3.29,2.49-.8c2.86-.93,4.81-2.27,5.84-4a7.32,7.32,0,0,0,.41-6.21,7.3,7.3,0,0,0-3.95-4.78q-2.82-1.23-7.09.15l-2.5.81Zm.54-1L102.69,24.9l1.73-.56a9.4,9.4,0,0,1,6.34-.19,6.44,6.44,0,0,1,3.69,4.37,6.47,6.47,0,0,1-.44,5.71,9.31,9.31,0,0,1-5.24,3.57ZM108.77,35a5.26,5.26,0,0,0,3.07-2.14A4.18,4.18,0,0,0,112,29.3a4.18,4.18,0,0,0-2.23-2.78,5.34,5.34,0,0,0-3.73.08Z"/>
                  <path className="cls-3" d="M103,45.12,95.49,21.75l7.33-2.37a21.29,21.29,0,0,1,5.66-1.06,12.48,12.48,0,0,1,5,.81,10.36,10.36,0,0,1,4,2.89,13.3,13.3,0,0,1,2.54,4.68,13.26,13.26,0,0,1,.68,5.28,10.38,10.38,0,0,1-1.57,4.69,12.52,12.52,0,0,1-3.6,3.62,20.55,20.55,0,0,1-5.22,2.47Zm5.66-16.85,1.24,3.83a1.57,1.57,0,0,0,.19-.26,2.34,2.34,0,0,0,0-1.92A2.3,2.3,0,0,0,109,28.37,1.7,1.7,0,0,0,108.7,28.27Z"/>
                  <polygon className="cls-2" points="256.76 94.89 244.47 94.89 246.52 116.38 252.94 148.83 250.62 184.25 234.06 213.29 220.01 229.82 188.97 243.47 167.15 247.22 138.49 253.76 138.62 274.36 172.58 274.36 209.93 255.87 243.7 229.82 269.67 199.18 277.09 150.52 277.09 118.19 260.04 94.89 256.76 94.89"/>
                  <path className="cls-3" d="M148.66,250.5l12.8-1.08.48,5.7-5.87.49.21,2.41,5.76-.48.46,5.41-5.77.48.21,2.51,6.09-.51.48,5.7-13,1.09Zm12.22,18.22-.06-.78-6.52.55-.6-7.13,6.19-.52-.06-.78-6.19.52-.59-7,6.51-.55-.06-.78-7.37.62,1.39,16.51Z"/>
                  <polygon className="cls-3" points="148.66 274.38 146.5 248.67 163.28 247.26 164.1 256.95 164 256.96 164.53 263.3 164.86 263.27 165.67 272.95 148.66 274.38"/>
                  <path className="cls-3" d="M187.11,251.29a8.58,8.58,0,0,1,.36,3.9,6.91,6.91,0,0,1-1.3,3.08,9.15,9.15,0,0,1-2.62,2.29,15.49,15.49,0,0,1-3.6,1.57l1.5,5-6.66,2-6.32-20.87,6.66-2a16.11,16.11,0,0,1,3.87-.69,9,9,0,0,1,3.45.46,6.72,6.72,0,0,1,2.79,1.83A8.61,8.61,0,0,1,187.11,251.29Zm-3,.92a5.93,5.93,0,0,0-1.22-2.3,4.72,4.72,0,0,0-1.87-1.27,6.5,6.5,0,0,0-2.38-.36,10.36,10.36,0,0,0-2.71.47l-3.54,1.07,4.81,15.86.81-.25-1.5-5,2.72-.83a10.28,10.28,0,0,0,2.51-1.12,6.33,6.33,0,0,0,1.78-1.61,4.74,4.74,0,0,0,.85-2.11A5.92,5.92,0,0,0,184.07,252.21Zm-.87.26a5.12,5.12,0,0,1,.21,2.4,4.08,4.08,0,0,1-.81,1.82A5.62,5.62,0,0,1,181,258a10.15,10.15,0,0,1-2.07.88l-2.72.83-2.85-9.39,2.72-.82a10,10,0,0,1,2.22-.42,5.64,5.64,0,0,1,2,.22,4.06,4.06,0,0,1,1.69,1.06A5.18,5.18,0,0,1,183.2,252.47Zm-5,4,.28-.08a2.67,2.67,0,0,0,1.71-1.18,2.5,2.5,0,0,0,.06-1.88,2.43,2.43,0,0,0-1.1-1.53,2.59,2.59,0,0,0-2.07,0l-.28.08Z"/>
                  <path className="cls-3" d="M173.46,271.62,166,246.93l8.58-2.6a18.14,18.14,0,0,1,4.36-.78,11.09,11.09,0,0,1,4.19.57,8.83,8.83,0,0,1,3.6,2.35,10.49,10.49,0,0,1,2.32,4.24h0a10.58,10.58,0,0,1,.43,4.81,9,9,0,0,1-1.69,4,11.23,11.23,0,0,1-3.18,2.8,17.59,17.59,0,0,1-2.17,1.09l1.54,5.07Z"/>
                  <path className="cls-3" d="M190.54,240.8l6-3.53,3.76,6.39,3.36-2-3.77-6.4,6-3.55L217,250.53l-6,3.55-4.09-7-3.35,2,4.09,7-6,3.54Zm13.29,14.44-4.2-7.12,8.28-4.88,4.2,7.12.73-.43-8.41-14.27-.74.43,3.82,6.47-8.28,4.88L195.41,241l-.73.44,8.41,14.27Z"/>
                  <polygon className="cls-3" points="200.9 262.32 187.8 240.09 206.62 229 219.72 251.24 210.34 256.76 210.34 256.76 200.9 262.32"/>
                  <path className="cls-3" d="M211.46,227.23l9.36-8.79,3.92,4.17-4.29,4,1.65,1.76,4.22-4,3.72,4-4.22,4,1.72,1.83L232,230l3.91,4.17-9.53,8.95Zm20.9,6.7-.54-.57-4.77,4.47-4.89-5.22,4.52-4.25-.53-.57L221.62,232l-4.83-5.15,4.76-4.47-.53-.57-5.39,5.05L227,239Z"/>
                  <polygon className="cls-3" points="226.29 245.96 208.63 227.14 220.91 215.62 227.57 222.7 227.5 222.77 231.85 227.41 232.09 227.19 238.74 234.27 226.29 245.96"/>
                  <path className="cls-3" d="M225.7,213.53l3.13-4.82,10.11-.22-3.94-9.3,3.13-4.83,18.29,11.86-3.79,5.84-6.58-4.26,3.07,7.28-7.92.2,6.58,4.27L244,225.39Zm18.34,7-12.59-8.17,12.92-.34-5-11.91L252,208.26l.46-.72-13.91-9-.46.71,5.06,12-13,.29-.46.71,13.91,9Z"/>
                  <polygon className="cls-3" points="244.58 228.16 222.93 214.12 225.17 210.67 224.96 210.53 225.27 210.52 227.73 206.73 235.95 206.55 232.74 199 235.19 195.22 235.06 194.9 235.3 195.05 237.54 191.6 259.19 205.63 253.22 214.83 250.42 213.01 252.1 217.01 247.7 217.12 250.54 218.96 244.58 228.16"/>
                  <path className="cls-3" d="M240.65,187.81l4.2-12.14,5.4,1.87-1.92,5.56,2.29.79,1.89-5.47,5.12,1.77-1.88,5.47,2.37.82,2-5.77,5.41,1.86-4.27,12.36Zm21.61-3.8-.74-.26-2.13,6.18-6.77-2.34,2-5.87-.74-.26-2,5.87-6.67-2.3,2.13-6.18-.74-.26-2.41,7L259.85,191Z"/>
                  <polygon className="cls-3" points="262.49 197.47 238.11 189.05 243.61 173.13 252.79 176.3 252.76 176.39 258.78 178.47 258.88 178.16 268.07 181.34 262.49 197.47"/>
                  <path className="cls-3" d="M246.62,168.82l.87-7.46a13.11,13.11,0,0,1,1-3.79,9.33,9.33,0,0,1,2-2.88,7.66,7.66,0,0,1,2.88-1.72,8.82,8.82,0,0,1,3.71-.31,7.67,7.67,0,0,1,2.71.78,8.19,8.19,0,0,1,1.76,1.23,7.15,7.15,0,0,1,1.26,1.62l7.62-3.22-1,8.11-5.86,2.61,5.48.64-.8,6.91Zm14.91-5.11a11.53,11.53,0,0,0,0-2.49l5.55-2.48.12-1-5.83,2.61a6.15,6.15,0,0,0-1.58-3,5.15,5.15,0,0,0-3.2-1.43,5.21,5.21,0,0,0-4.55,1.56,7,7,0,0,0-1.36,2.13,10,10,0,0,0-.7,2.78l-.41,3.51,16.46,1.92.1-.85-4.94-.57Zm-1.09,2.58-10-1.16.31-2.67a7.37,7.37,0,0,1,1.83-4.47,4.46,4.46,0,0,1,3.9-1.24,4.37,4.37,0,0,1,3.49,2.1,7.51,7.51,0,0,1,.74,4.77Zm-2.36-3.14,0-.36a3.9,3.9,0,0,0-.31-2.16,2.45,2.45,0,0,0-3.7-.43,3.83,3.83,0,0,0-.8,2l0,.36Z"/>
                  <path className="cls-3" d="M270,173.56l-25.62-3,1.1-9.45a14.8,14.8,0,0,1,1.16-4.37,11,11,0,0,1,2.41-3.51,9.61,9.61,0,0,1,3.65-2.18,10.54,10.54,0,0,1,4.56-.39,9.47,9.47,0,0,1,3.43,1,11,11,0,0,1,2.18,1.52c.21.21.4.41.59.63l9.3-4-1.48,12.67-.23.1.22,0Z"/>
                  <path className="cls-3" d="M248.25,144.83l-.56-7.36,21.74-1.66.56,7.37Zm18.82-5.56-16.52,1.26.06.84,16.53-1.25Z"/>
                  <rect className="cls-3" x="245.94" y="134.63" width="25.8" height="11.39" transform="translate(-9.9 20.05) rotate(-4.35)"/>
                  <path className="cls-3" d="M261.43,126.94a11.34,11.34,0,0,0,0-1.54,12.33,12.33,0,0,0-.11-1.51,13.31,13.31,0,0,0-.31-1.65c-.24-1-.73-1.37-1.44-1.19a1.18,1.18,0,0,0-.73.65,11.52,11.52,0,0,0-.67,1.36c-.24.54-.5,1.13-.8,1.75a11.09,11.09,0,0,1-1.09,1.82,7.93,7.93,0,0,1-1.58,1.59,6,6,0,0,1-2.25,1,6.26,6.26,0,0,1-2.77.12,5.56,5.56,0,0,1-2.27-1,6.93,6.93,0,0,1-1.72-2,10,10,0,0,1-1.08-2.64c-.12-.51-.22-1-.3-1.55s-.14-1-.19-1.4-.11-.95-.15-1.41l5.93-1.48c0,.18,0,.37.07.59s0,.37.08.59.08.44.14.67.08.28.13.47a2.71,2.71,0,0,0,.22.52,1,1,0,0,0,.33.39.55.55,0,0,0,.49.08c.17,0,.35-.24.53-.59s.39-.77.63-1.26.51-1,.83-1.66a9.36,9.36,0,0,1,1.18-1.74,9.1,9.1,0,0,1,1.67-1.53,6.59,6.59,0,0,1,2.31-1,7.46,7.46,0,0,1,2.72-.21,5.61,5.61,0,0,1,2.47.85,6.76,6.76,0,0,1,2,2,10.63,10.63,0,0,1,1.38,3.33,22.52,22.52,0,0,1,.45,2.26c.1.71.16,1.34.19,1.9a15.28,15.28,0,0,1,0,1.77Zm3.14-3.61a9,9,0,0,0-.08-1q0-.47-.12-1c-.05-.34-.12-.68-.2-1a7.31,7.31,0,0,0-.64-1.67,4.37,4.37,0,0,0-1.06-1.34,3.6,3.6,0,0,0-1.48-.76,4,4,0,0,0-1.92.06,3.6,3.6,0,0,0-1.63.9,8.11,8.11,0,0,0-1.3,1.57c-.38.6-.74,1.23-1.07,1.88s-.68,1.28-1,1.88a8.93,8.93,0,0,1-1.13,1.53,2.83,2.83,0,0,1-1.36.83,2.25,2.25,0,0,1-1.94-.3,3.67,3.67,0,0,1-1.21-2.19c0-.17-.08-.34-.11-.52s-.06-.37-.08-.54a4.18,4.18,0,0,0-.1-.51l-.82.2c0,.15.06.31.08.48s0,.33.08.51.07.37.12.56a5,5,0,0,0,1.48,2.63,2.87,2.87,0,0,0,2.71.5,3.36,3.36,0,0,0,1.55-.88,8.57,8.57,0,0,0,1.23-1.55c.37-.6.73-1.23,1.06-1.88s.67-1.28,1-1.88a8.57,8.57,0,0,1,1.18-1.54,3.06,3.06,0,0,1,1.47-.85,2.81,2.81,0,0,1,2.57.45,4.79,4.79,0,0,1,1.51,2.64,12.34,12.34,0,0,1,.33,2c0,.32.06.64.07,1Z"/>
                  <path className="cls-3" d="M250.74,131.48a7.09,7.09,0,0,1-1.46-.15,7.4,7.4,0,0,1-3.09-1.38,9.1,9.1,0,0,1-2.22-2.5,11.64,11.64,0,0,1-1.31-3.2c-.14-.58-.25-1.17-.34-1.75l-.2-1.39-.33-3.22,9.8-2.44.14,1.06A10.89,10.89,0,0,1,253,114.7a11.46,11.46,0,0,1,2-1.87,8.63,8.63,0,0,1,3-1.34,9.74,9.74,0,0,1,3.42-.26,7.7,7.7,0,0,1,3.32,1.14,8.86,8.86,0,0,1,2.63,2.62,12.47,12.47,0,0,1,1.66,4,22.89,22.89,0,0,1,.49,2.47c.11.76.17,1.46.2,2.07a16.18,16.18,0,0,1,0,2l-.06,1.5-10.45,2.61.18-2.73a8.9,8.9,0,0,0,0-1.26v-.17a1.93,1.93,0,0,0,0-.24c-.08.16-.16.34-.24.51a12.7,12.7,0,0,1-1.29,2.16,10,10,0,0,1-2,2,8.17,8.17,0,0,1-3,1.39A9.25,9.25,0,0,1,250.74,131.48Zm-.05-8.26a.68.68,0,0,0,.08.11.85.85,0,0,0,.29,0l.09,0A2.32,2.32,0,0,1,250.69,123.22Zm8.31-.67Z"/>
                  <path className="cls-1" d="M144.05,52.26a92.63,92.63,0,1,1-65.5,27.13,92,92,0,0,1,65.5-27.13m0-5.94a98.57,98.57,0,1,0,98.57,98.57,98.57,98.57,0,0,0-98.57-98.57Z"/>
                  <path className="cls-1" d="M144.2,102.19c0,.23.11,1.93,0,5.16-.11-1.57-.2-2.49-.22-2.64,0,.17.14,1.37.18,3.63-.5,10.46-3.18,34.34-15.78,72.64C110.77,234.42,52,244,52,244c-1.83.18,2.06,3.74,6.07,7L58,251c-3.4.5,13.62,11.83,13.62,11.83,74.12-31.47,74.14-127.18,72.82-152.14C144.41,105.59,144.22,102.5,144.2,102.19Z"/>
                  <path className="cls-1" d="M144.2,102.19v0Z"/>
                  <path className="cls-1" d="M144,104.71v0Z"/>
                  <path className="cls-5" d="M143.67,286.84A142.33,142.33,0,0,1,43,43.87,142.33,142.33,0,1,1,244.31,245.15,141.4,141.4,0,0,1,143.67,286.84Zm0-278.72A136.39,136.39,0,0,0,47.23,241,136.39,136.39,0,1,0,240.11,48.07,135.5,135.5,0,0,0,143.67,8.12Z"/>
                  <path className="cls-5" d="M144.27,96.39c0,.22.09,2-.08,5.21-.11-1.59-.2,14.13-.22,14,0,.18.13-15.25.16-13-.68,10.57-3.9,34.73-18.11,73.58-19.83,54.21-83.95,64.59-83.95,64.59-2,.2,2.21,3.75,6.54,7l-.17,0c-3.7.54,14.69,11.77,14.69,11.77,81.13-32.72,82.36-129.42,81.24-154.62C144.45,99.81,144.29,96.7,144.27,96.39Z"/>
                  <path className="cls-5" d="M144.27,96.39v0Z"/>
                  <path className="cls-5" d="M144,115.57v0Z"/>
                  <path className="cls-2" d="M147.85,100.82c0-.45,0-.63,0-.41s0,.15,0,.24S147.85,100.76,147.85,100.82Z"/>
                  <path className="cls-2" d="M178.44,220.87c7.2-3.48,11.21-5.81,13.67-7.72-11.69-9.43-19.91-21.91-26.17-39-14.09-38.53-17.37-62.6-18.09-73.31,0,.48,0,1.28,0,2.24,0-.94,0-1.78,0-2.41v-.24c0-.8,0-1.19-.06-.87a51.11,51.11,0,0,1-.08-5.21c0,.31-.18,3.43-.1,8.59C146.76,121.6,145.07,178.8,178.44,220.87Z"/>
                  <polygon className="cls-4" points="137.68 66.61 143.35 51.26 149.69 66.35 165.05 72.03 149.95 78.36 144.28 93.72 137.94 78.63 122.58 72.95 137.68 66.61"/>
                  <polygon className="cls-3" points="149.91 66.56 143.97 52.42 143.97 92.68 144.5 93.93 150.17 78.57 165.26 72.24 149.91 66.56"/>
                  <path className="cls-5" d="M140.12,238.71a1,1,0,0,1-.6-.19.55.55,0,0,1-.18-.47.54.54,0,0,1,.24-.45,1,1,0,0,1,.6-.12.94.94,0,0,1,.61.18.55.55,0,0,1,.18.47.54.54,0,0,1-.24.45A1.09,1.09,0,0,1,140.12,238.71Z"/>
                  <path className="cls-5" d="M146.17,238.8a1.06,1.06,0,0,1-.63-.15.61.61,0,0,1,0-.91.94.94,0,0,1,.59-.17,1,1,0,0,1,.62.14.58.58,0,0,1,.21.46.55.55,0,0,1-.21.46A1,1,0,0,1,146.17,238.8Z"/>
                  <path className="cls-5" d="M152.21,238.49a1,1,0,0,1-.63-.1.55.55,0,0,1-.23-.44.55.55,0,0,1,.17-.48,1.06,1.06,0,0,1,.59-.2.93.93,0,0,1,.62.1.56.56,0,0,1,.24.44.55.55,0,0,1-.18.48A1,1,0,0,1,152.21,238.49Z"/>
                  <path className="cls-5" d="M155.94,232.05l3.91-.62a.69.69,0,0,1,.4,0c.09,0,.15.15.18.32a1.34,1.34,0,0,1-.11.69,6.24,6.24,0,0,0-2.37,0l-.21,0c0,.29.11.86.25,1.72l2.17-.34a1.45,1.45,0,0,1,.14.46c0,.1,0,.17,0,.2a.3.3,0,0,1-.19.09l-2,.31c.18,1.1.29,1.77.34,2l.36-.06a6.43,6.43,0,0,0,2.24-.7,1.1,1.1,0,0,1,.29.58.4.4,0,0,1-.12.37,1.55,1.55,0,0,1-.7.21l-3.82.62a1.07,1.07,0,0,1-.14-.36.21.21,0,0,1,0-.18.3.3,0,0,1,.18-.08l.31-.05c0-.37-.18-1.34-.43-2.89-.13-.84-.23-1.44-.31-1.81l-.49.08a1,1,0,0,1-.13-.35.25.25,0,0,1,0-.18A.37.37,0,0,1,155.94,232.05Z"/>
                  <path className="cls-5" d="M162.92,230.87l2.29-.59c.27.51.55,1,.84,1.51s.58,1,.87,1.56a5,5,0,0,0,.46.8s0-.59,0-1.75,0-2.05-.07-2.67l2.21-.57a.85.85,0,0,1,.15.3.4.4,0,0,1,0,.22.24.24,0,0,1-.16.1l-.32.08c.12.48.35,1.26.69,2.37s.57,1.83.69,2.18l.4-.1a.54.54,0,0,1,.15.29.27.27,0,0,1,0,.22c0,.05-.08.08-.16.1l-1.92.5a1,1,0,0,1-.17-.34.25.25,0,0,1,0-.18.26.26,0,0,1,.17-.1l.16,0c-.12-.55-.49-1.86-1.1-3.94,0,1,0,1.84,0,2.58a8.26,8.26,0,0,1-.13,1.42.67.67,0,0,1-.53.43,2.7,2.7,0,0,1-.73.14,4.9,4.9,0,0,1-.67-.94c-.39-.6-.92-1.51-1.6-2.73.54,2.13.88,3.46,1,4l.32-.08a.64.64,0,0,1,.16.29.31.31,0,0,1,0,.22s-.08.08-.16.1l-1.76.46a1,1,0,0,1-.17-.34.21.21,0,0,1,0-.18.29.29,0,0,1,.17-.1l.28-.07q-.11-.56-.45-2.25t-.54-2.4l-.48.12a1,1,0,0,1-.16-.33.2.2,0,0,1,0-.19A.26.26,0,0,1,162.92,230.87Z"/>
                  <path className="cls-5" d="M174.33,227.41a3.05,3.05,0,0,1,.79-.24,17.09,17.09,0,0,1,1.59,1.85c1,1.23,1.69,2.07,2.1,2.53l.38-.14a.8.8,0,0,1,.18.27.3.3,0,0,1,0,.22.19.19,0,0,1-.15.12l-2.09.77a1.22,1.22,0,0,1-.2-.32.24.24,0,0,1,0-.19.29.29,0,0,1,.16-.11l.18-.07c-.12-.18-.36-.53-.75-1.06l-1.73.64c.06.63.11,1,.14,1.28l.39-.15a.84.84,0,0,1,.18.28.3.3,0,0,1,0,.22.24.24,0,0,1-.14.12l-1.79.65a1,1,0,0,1-.19-.32.19.19,0,0,1,0-.18.3.3,0,0,1,.16-.12l.22-.08q0-.9-.06-2.88c-.05-1.33-.05-2.14,0-2.44A.81.81,0,0,1,174.33,227.41Zm.42,3.52,1.35-.5a13.51,13.51,0,0,0-1.45-1.8A12.85,12.85,0,0,0,174.75,230.93Z"/>
                  <path className="cls-5" d="M179.58,225.43l1.68-.8c.68.51,1.31,1,1.91,1.39a18.71,18.71,0,0,1,2,1.65l-.86-1.81c-.37-.77-.64-1.31-.81-1.64l-.45.21a.89.89,0,0,1-.22-.3.2.2,0,0,1,0-.18.34.34,0,0,1,.15-.14l1.77-.84a.86.86,0,0,1,.21.26.39.39,0,0,1,0,.22.3.3,0,0,1-.14.13l-.34.16c.15.34.4.9.77,1.67l1.26,2.63a1,1,0,0,1,.14.52c0,.09-.17.19-.41.31a3,3,0,0,1-.86.34,8.16,8.16,0,0,1-1.35-.81,20.63,20.63,0,0,1-2.48-1.93c.86,1.8,1.37,2.86,1.54,3.19l.41-.19a.75.75,0,0,1,.21.26.3.3,0,0,1,0,.21.24.24,0,0,1-.14.14l-1.74.83a.81.81,0,0,1-.23-.3.2.2,0,0,1,0-.18.41.41,0,0,1,.15-.14l.33-.15q-.23-.51-1.23-2.64c-.37-.77-.65-1.32-.82-1.64l-.41.19a.89.89,0,0,1-.22-.3.22.22,0,0,1,0-.19A.28.28,0,0,1,179.58,225.43Z"/>
                  <path className="cls-5" d="M189.15,220.35a3.77,3.77,0,0,1,.73-.38,15.18,15.18,0,0,1,1.91,1.52c1.21,1,2.05,1.71,2.54,2.09l.35-.22a.87.87,0,0,1,.23.24.34.34,0,0,1,.06.21.21.21,0,0,1-.13.15l-1.9,1.15a1.13,1.13,0,0,1-.26-.28.21.21,0,0,1,0-.18.25.25,0,0,1,.14-.15l.16-.1q-.21-.22-.93-.9l-1.58.95c.17.61.3,1,.38,1.23l.35-.21a.59.59,0,0,1,.23.24.28.28,0,0,1,.07.21.29.29,0,0,1-.13.14l-1.62,1a1.13,1.13,0,0,1-.26-.28.21.21,0,0,1,0-.18.35.35,0,0,1,.13-.15l.2-.12c-.11-.58-.31-1.52-.6-2.81a24.07,24.07,0,0,1-.47-2.4A.8.8,0,0,1,189.15,220.35Zm1.08,3.38,1.23-.74a13.58,13.58,0,0,0-1.76-1.5A12.43,12.43,0,0,0,190.23,223.73Z"/>
                  <path className="cls-5" d="M197.61,221.33c-.21-.31-.76-1.11-1.68-2.38-.5-.7-.83-1.15-1-1.38-.79.64-1.23,1-1.33,1.06a.51.51,0,0,1-.3.12.38.38,0,0,1-.25-.19,1.63,1.63,0,0,1-.26-.61,9.79,9.79,0,0,0,2-1.14l2.38-1.7a.56.56,0,0,1,.28-.13c.08,0,.16.06.25.17a1.43,1.43,0,0,1,.25.75,4.46,4.46,0,0,0-1.76.8l-.09.06c.16.24.48.71,1,1.4.91,1.28,1.48,2.06,1.71,2.36l.54-.39a.7.7,0,0,1,.26.22.32.32,0,0,1,.07.2.21.21,0,0,1-.11.16l-2.09,1.5a1,1,0,0,1-.28-.26.2.2,0,0,1,0-.18.24.24,0,0,1,.12-.15Z"/>
                  <path className="cls-5" d="M199.18,213.45l1.83-1.52a.79.79,0,0,1,.27.2.37.37,0,0,1,.09.2.22.22,0,0,1-.1.16l-.33.27c.23.3.61.77,1.16,1.43,1,1.21,1.63,1.95,1.88,2.22l.45-.37a.86.86,0,0,1,.26.2.34.34,0,0,1,.1.2.28.28,0,0,1-.11.17l-1.84,1.53a1,1,0,0,1-.29-.24.21.21,0,0,1-.06-.17.28.28,0,0,1,.11-.17l.31-.26c-.23-.29-.84-1-1.85-2.25-.54-.65-.94-1.12-1.19-1.4l-.44.38a1.06,1.06,0,0,1-.3-.24.21.21,0,0,1-.06-.17A.33.33,0,0,1,199.18,213.45Z"/>
                  <path className="cls-5" d="M203.15,210.08l1.33-1.3c.8.26,1.55.5,2.25.7a20.59,20.59,0,0,1,2.44.92l-1.4-1.44c-.59-.6-1-1-1.29-1.29l-.36.34a.81.81,0,0,1-.3-.21.19.19,0,0,1-.08-.17.28.28,0,0,1,.1-.17l1.4-1.37a.9.9,0,0,1,.28.18.34.34,0,0,1,.11.2.22.22,0,0,1-.09.16l-.27.27c.25.27.67.72,1.27,1.33l2,2.09c.19.2.29.34.29.44s-.09.24-.28.43a3.31,3.31,0,0,1-.71.6,8.89,8.89,0,0,1-1.54-.34,20.25,20.25,0,0,1-3-1c1.39,1.43,2.22,2.27,2.49,2.53l.33-.32a.75.75,0,0,1,.28.18.29.29,0,0,1,.1.19.19.19,0,0,1-.08.17l-1.39,1.35a.85.85,0,0,1-.31-.21.21.21,0,0,1-.08-.16.33.33,0,0,1,.1-.18l.26-.25c-.24-.28-.92-1-2-2.1-.6-.61-1-1-1.31-1.3l-.32.32a1.2,1.2,0,0,1-.31-.21.22.22,0,0,1-.07-.17A.24.24,0,0,1,203.15,210.08Z"/>
                  <path className="cls-5" d="M215.22,204.72c-.42-.36-.68-.58-.8-.66a1.38,1.38,0,0,0-.25-.14.18.18,0,0,0-.16,0,.4.4,0,0,0-.15.12.68.68,0,0,1-.27-.13.31.31,0,0,1-.13-.22.41.41,0,0,1,.12-.24l.61-.72a.61.61,0,0,1,.33-.24.57.57,0,0,1,.36.17l.69.6c.29.24.68.56,1.17.93a3.6,3.6,0,0,1-.34.47s-.19.07-.45.06a3.69,3.69,0,0,1-.77,1.5,2.45,2.45,0,0,1-1.76,1,3.05,3.05,0,0,1-2-.92,3.49,3.49,0,0,1-1.4-2.23,3,3,0,0,1,.85-2.27,4.33,4.33,0,0,1,.94-.89.46.46,0,0,1,.59,0,1,1,0,0,1,.34.64,3.72,3.72,0,0,0-1.43,1,1.08,1.08,0,0,0-.28,1,2.94,2.94,0,0,0,1,1.31,3.92,3.92,0,0,0,1.66,1,1.26,1.26,0,0,0,1.1-.53A3.11,3.11,0,0,0,215.22,204.72Z"/>
                  <path className="cls-5" d="M216.14,195.09l2.16-3.32a.72.72,0,0,1,.29-.29.46.46,0,0,1,.36.09,1.26,1.26,0,0,1,.44.54,6.47,6.47,0,0,0-1.59,1.76l-.12.18c.23.17.75.51,1.55,1l1.17-1.79a1.46,1.46,0,0,1,.44.2c.08.05.13.1.14.15a.25.25,0,0,1-.07.19l-1.07,1.65q1.32.85,1.71,1.08l.41-.63a.76.76,0,0,1,.31.11.32.32,0,0,1,.15.17.26.26,0,0,1,0,.19L221,198.48a1,1,0,0,1-.36-.13.29.29,0,0,1-.11-.15.38.38,0,0,1,.06-.19l.18-.27q-.45-.33-2.43-1.62c-.71-.46-1.23-.79-1.55-1l-.27.41a.91.91,0,0,1-.35-.13c-.07-.05-.11-.1-.11-.15A.25.25,0,0,1,216.14,195.09Z"/>
                  <path className="cls-5" d="M225.3,190.34l.3-.56a.85.85,0,0,1,.32.09.33.33,0,0,1,.16.15.23.23,0,0,1,0,.19l-1.16,2.15a1,1,0,0,1-.36-.11.25.25,0,0,1-.13-.14.3.3,0,0,1,0-.19l.19-.36c-.32-.19-1.17-.65-2.56-1.4-.74-.4-1.29-.69-1.62-.85l-.27.51a1.17,1.17,0,0,1-.36-.11.29.29,0,0,1-.13-.14.3.3,0,0,1,.05-.19l1.07-2a3.73,3.73,0,0,1,1.25-1.53,1.32,1.32,0,0,1,1.34.07,1.65,1.65,0,0,1,.73,2,1.55,1.55,0,0,1,.69-.31,5,5,0,0,1,1-.05,2.16,2.16,0,0,0,.83-.06.83.83,0,0,0,.37-.39c.26.06.41.15.45.28s0,.37-.21.71a2.31,2.31,0,0,1-.57.76,1.59,1.59,0,0,1-1,0,3.67,3.67,0,0,0-1.18-.13.76.76,0,0,0-.63.41,1.86,1.86,0,0,0-.15.38Zm-2.23-1.18.17-.31a1.46,1.46,0,0,0,.22-.86.78.78,0,0,0-.45-.58,1.08,1.08,0,0,0-.89-.15,1.2,1.2,0,0,0-.58.54,2.17,2.17,0,0,0-.17.42c.16.1.61.35,1.36.75Z"/>
                  <path className="cls-5" d="M226.28,183.85a3.56,3.56,0,0,1-2-1.72,2.86,2.86,0,0,1,.1-2.3,2.82,2.82,0,0,1,1.32-1.57,2.86,2.86,0,0,1,2.27.25,3.49,3.49,0,0,1,2,1.71,2.92,2.92,0,0,1-.11,2.34,2.77,2.77,0,0,1-1.3,1.53A2.89,2.89,0,0,1,226.28,183.85Zm.2-1.55a3.43,3.43,0,0,0,1.87.42,1.15,1.15,0,0,0,.83-.72,1,1,0,0,0-.06-1.05,3.34,3.34,0,0,0-1.37-.9,3.53,3.53,0,0,0-1.88-.42,1.16,1.16,0,0,0-.82.72,1,1,0,0,0,.06,1.06A3.24,3.24,0,0,0,226.48,182.3Z"/>
                  <path className="cls-5" d="M226.43,174.94l.71-2.27c.57,0,1.15.07,1.73.09l1.78.09a5.82,5.82,0,0,0,.92,0s-.49-.33-1.48-.93-1.76-1.06-2.3-1.35l.68-2.18a.93.93,0,0,1,.33,0q.15,0,.18.12a.24.24,0,0,1,0,.19l-.1.31q.71.23,2.37.66c1.12.29,1.86.48,2.22.56l.13-.39a.74.74,0,0,1,.33,0,.28.28,0,0,1,.18.12.24.24,0,0,1,0,.19l-.59,1.9a1.48,1.48,0,0,1-.38,0A.23.23,0,0,1,233,172a.25.25,0,0,1,0-.19l0-.16c-.53-.18-1.84-.56-3.93-1.13.85.49,1.57,1,2.18,1.36a8.14,8.14,0,0,1,1.14.86.64.64,0,0,1,.09.67,3.05,3.05,0,0,1-.26.7,5.1,5.1,0,0,1-1.15.07c-.72,0-1.77,0-3.16-.07,2.09.66,3.4,1.07,3.94,1.22l.1-.32a.74.74,0,0,1,.33,0,.36.36,0,0,1,.19.11.34.34,0,0,1,0,.2L232,177a1.15,1.15,0,0,1-.38,0,.19.19,0,0,1-.14-.12.25.25,0,0,1,0-.19l.09-.27c-.35-.14-1.07-.41-2.15-.8s-1.86-.67-2.33-.81l-.14.47a1,1,0,0,1-.38,0,.2.2,0,0,1-.14-.11A.27.27,0,0,1,226.43,174.94Z"/>
                  <path className="cls-5" d="M230.19,159.6a2.94,2.94,0,0,1,.18-.8,15.92,15.92,0,0,1,2.39-.49c1.55-.27,2.63-.47,3.23-.6l.06-.41a.74.74,0,0,1,.33,0,.25.25,0,0,1,.2.09.19.19,0,0,1,0,.19l-.35,2.19a.94.94,0,0,1-.37,0,.2.2,0,0,1-.16-.09.26.26,0,0,1,0-.2l0-.19c-.21,0-.64.06-1.28.14l-.29,1.82c.58.26,1,.42,1.19.5l.06-.41a.74.74,0,0,1,.33,0,.34.34,0,0,1,.2.09.2.2,0,0,1,0,.19l-.29,1.87a1,1,0,0,1-.38,0,.2.2,0,0,1-.16-.09.26.26,0,0,1,0-.2l0-.23c-.52-.29-1.37-.74-2.54-1.35s-1.89-1-2.14-1.17A.79.79,0,0,1,230.19,159.6Zm3.28,1.35.23-1.43a13.45,13.45,0,0,0-2.29.4A15.09,15.09,0,0,0,233.47,161Z"/>
                  <path className="cls-5" d="M231.84,150.61l0,.49a1,1,0,0,1-.37.05.21.21,0,0,1-.17-.07.3.3,0,0,1,0-.2l.13-2a.77.77,0,0,1,.33-.06.34.34,0,0,1,.21.07.26.26,0,0,1,.05.19l0,.33c.36,0,1.23.1,2.59.19a3,3,0,0,1,2.19.8,2.79,2.79,0,0,1,.46,2,2.3,2.3,0,0,1-.53,1.56,2.26,2.26,0,0,1-1.62.33c-1.9-.12-3-.19-3.41-.2l0,.4a1,1,0,0,1-.37.05.21.21,0,0,1-.17-.07.27.27,0,0,1,0-.19l.14-2.08a.76.76,0,0,1,.33-.05.29.29,0,0,1,.21.07.24.24,0,0,1,.05.18l0,.3c.38,0,1.22.1,2.52.19a3.51,3.51,0,0,0,1.68-.15.87.87,0,0,0,.45-.78.9.9,0,0,0-.29-.84,2.91,2.91,0,0,0-1.39-.33C233.16,150.67,232.22,150.61,231.84,150.61Z"/>
                  <path className="cls-5" d="M231.25,144.23a5.18,5.18,0,0,1,.1-1.48.48.48,0,0,1,.5-.37.9.9,0,0,1,.66.25,4.33,4.33,0,0,0-.42,1.75,1,1,0,0,0,.18.68.58.58,0,0,0,.49.18.67.67,0,0,0,.57-.25,3.12,3.12,0,0,0,.38-1,4.13,4.13,0,0,1,.58-1.42,1.46,1.46,0,0,1,1.18-.54,1.58,1.58,0,0,1,1.41.65,3.52,3.52,0,0,1,.49,2,5,5,0,0,1-.11,1.38.55.55,0,0,1-.54.46.84.84,0,0,1-.66-.29,4.1,4.1,0,0,0,.47-1.88,1.58,1.58,0,0,0-.16-.79.59.59,0,0,0-.51-.22.74.74,0,0,0-.63.37,4.88,4.88,0,0,0-.43,1.17,2.52,2.52,0,0,1-.62,1.25,1.5,1.5,0,0,1-1,.4,1.63,1.63,0,0,1-1.37-.6A2.69,2.69,0,0,1,231.25,144.23Z"/>
                  <path className="cls-5" d="M236.3,137.48c-.37,0-1.34.11-2.9.27-.85.08-1.41.15-1.69.19.16,1,.24,1.56.25,1.68a.5.5,0,0,1,0,.32.4.4,0,0,1-.29.13A2,2,0,0,1,231,140a9.62,9.62,0,0,0,0-2.27l-.29-2.91a.49.49,0,0,1,0-.31.35.35,0,0,1,.27-.13,1.43,1.43,0,0,1,.77.16,4.57,4.57,0,0,0-.18,1.92l0,.1c.28,0,.85-.06,1.69-.15,1.57-.16,2.53-.26,2.9-.32l-.07-.66a.72.72,0,0,1,.31-.11c.11,0,.18,0,.22,0a.21.21,0,0,1,.08.17l.26,2.56a1,1,0,0,1-.36.11.19.19,0,0,1-.17-.05.24.24,0,0,1-.08-.18Z"/>
                  <path className="cls-5" d="M230.24,132.18l-.41-2.34a.76.76,0,0,1,.31-.13.22.22,0,0,1,.31.19l.07.41c.37-.05,1-.15,1.81-.3,1.55-.27,2.5-.44,2.87-.52l-.1-.58a.66.66,0,0,1,.3-.12.31.31,0,0,1,.22,0,.22.22,0,0,1,.09.17l.42,2.36a1.11,1.11,0,0,1-.35.14.24.24,0,0,1-.19,0,.3.3,0,0,1-.08-.18l-.07-.4q-.56.07-2.88.48c-.84.15-1.44.27-1.8.35l.1.57a1.07,1.07,0,0,1-.34.14.22.22,0,0,1-.19,0A.3.3,0,0,1,230.24,132.18Z"/>
                  <path className="cls-5" d="M229.29,127.06l-.47-1.81c.63-.56,1.2-1.1,1.73-1.6a18.52,18.52,0,0,1,2-1.66l-1.94.5q-1.25.32-1.77.48l.12.48a1,1,0,0,1-.33.16.24.24,0,0,1-.19,0,.26.26,0,0,1-.1-.17l-.49-1.9a.89.89,0,0,1,.29-.15.3.3,0,0,1,.23,0,.28.28,0,0,1,.1.16l.09.37c.37-.08,1-.23,1.79-.44l2.82-.73a.93.93,0,0,1,.53,0c.09,0,.17.2.24.46a3.79,3.79,0,0,1,.17.91,9.33,9.33,0,0,1-1.06,1.17,20.68,20.68,0,0,1-2.38,2.07c1.94-.5,3.08-.8,3.44-.91L234,124a.73.73,0,0,1,.29-.15.27.27,0,0,1,.22,0,.24.24,0,0,1,.1.16l.49,1.87a.91.91,0,0,1-.34.17c-.08,0-.14,0-.18,0a.26.26,0,0,1-.1-.17l-.09-.35c-.37.08-1.31.31-2.84.7-.82.22-1.41.38-1.76.49l.11.43a.87.87,0,0,1-.33.17.2.2,0,0,1-.19,0A.26.26,0,0,1,229.29,127.06Z"/>
                  <path className="cls-5" d="M231.09,116.05a.84.84,0,0,1,0-.56.62.62,0,0,1,.41-.35,2.43,2.43,0,0,1,1.33,0c.56.15.87.33.94.54a.36.36,0,0,1-.17.44,2.91,2.91,0,0,0-1.26-.38c.07.43-.07.7-.39.81a.56.56,0,0,1-.49,0A.94.94,0,0,1,231.09,116.05Z"/>
                  <path className="cls-5" d="M227.77,106.45c-.35.14-1.22.56-2.64,1.24l-1.52.76q.73,1.33.81,1.5a.44.44,0,0,1,.07.31c0,.08-.1.15-.23.22a1.88,1.88,0,0,1-.64.15,10.82,10.82,0,0,0-.79-2.13l-1.28-2.63a.46.46,0,0,1-.07-.3c0-.08.08-.15.2-.21a1.37,1.37,0,0,1,.78-.12,4.65,4.65,0,0,0,.49,1.86l.05.1c.26-.11.78-.35,1.55-.72l2.61-1.29-.29-.6a.72.72,0,0,1,.25-.21.28.28,0,0,1,.22,0,.21.21,0,0,1,.13.13l1.12,2.32a.93.93,0,0,1-.3.23.19.19,0,0,1-.18,0,.3.3,0,0,1-.13-.14Z"/>
                  <path className="cls-5" d="M224.12,99.52l-.25-.42a.63.63,0,0,1,.23-.23.26.26,0,0,1,.22-.06.22.22,0,0,1,.14.12l1.09,1.81a1.07,1.07,0,0,1-.28.25.21.21,0,0,1-.18,0,.23.23,0,0,1-.15-.13l-.14-.24c-.32,0-1.31,0-3,.08-.53,1-.9,1.82-1.12,2.36l.19.31a1.1,1.1,0,0,1-.27.26.23.23,0,0,1-.19,0,.31.31,0,0,1-.14-.14l-1.14-1.89a.83.83,0,0,1,.24-.22.31.31,0,0,1,.22-.07.25.25,0,0,1,.14.13l.07.11a19.69,19.69,0,0,0,1-1.74,13.85,13.85,0,0,0-2,.16l.19.32a.91.91,0,0,1-.27.25.23.23,0,0,1-.19,0,.31.31,0,0,1-.14-.14l-1-1.61a.87.87,0,0,1,.24-.23.34.34,0,0,1,.21-.06.21.21,0,0,1,.15.13l.11.17a26.67,26.67,0,0,0,3,.07,11.94,11.94,0,0,0,1-2.29,1,1,0,0,0-.11-.58.7.7,0,0,1,.23-.22.29.29,0,0,1,.23,0,.58.58,0,0,1,.22.24,10.12,10.12,0,0,1,.61,1.08.56.56,0,0,1,0,.4,6.66,6.66,0,0,1-.35.67l-.87,1.55A13,13,0,0,0,224.12,99.52Z"/>
                  <path className="cls-5" d="M220.12,92.08a1,1,0,0,1,.23.59.57.57,0,0,1-.26.43.54.54,0,0,1-.5.09,1,1,0,0,1-.48-.4,1,1,0,0,1-.23-.59.61.61,0,0,1,.76-.52A1,1,0,0,1,220.12,92.08Z"/>
                  <path className="cls-5" d="M216.45,87.28a1,1,0,0,1,.27.58.59.59,0,0,1-.23.44.51.51,0,0,1-.49.12,1.24,1.24,0,0,1-.77-.94.52.52,0,0,1,.23-.44.51.51,0,0,1,.49-.12A1,1,0,0,1,216.45,87.28Z"/>
                  <path className="cls-5" d="M212.48,82.73a1,1,0,0,1,.3.56.6.6,0,0,1-.68.61,1,1,0,0,1-.52-.33.94.94,0,0,1-.31-.56.53.53,0,0,1,.2-.45.54.54,0,0,1,.48-.16A1,1,0,0,1,212.48,82.73Z"/>
                  <path className="cls-1" d="M148.59,250.48l12.8-1.07.48,5.7-5.86.49.2,2.41,5.77-.49.45,5.41-5.76.48.21,2.51,6.09-.51.48,5.7-13,1.09Zm12.23,18.23-.07-.78-6.51.54-.6-7.13,6.19-.52-.07-.78-6.19.52-.59-7,6.52-.54-.07-.79-7.36.62,1.39,16.52Z"/>
                  <path className="cls-1" d="M187,251.27a8.59,8.59,0,0,1,.37,3.9,6.88,6.88,0,0,1-1.31,3.08,9.29,9.29,0,0,1-2.61,2.3,16.47,16.47,0,0,1-3.61,1.57l1.51,5-6.66,2-6.33-20.86,6.67-2a15.67,15.67,0,0,1,3.87-.7,9.25,9.25,0,0,1,3.45.46,6.85,6.85,0,0,1,2.79,1.83A8.48,8.48,0,0,1,187,251.27Zm-3,.92a5.88,5.88,0,0,0-1.22-2.29,4.68,4.68,0,0,0-1.88-1.28,6.27,6.27,0,0,0-2.38-.35,10.4,10.4,0,0,0-2.71.46l-3.53,1.07,4.81,15.86.81-.25-1.51-5,2.72-.82a10.49,10.49,0,0,0,2.51-1.12,6.56,6.56,0,0,0,1.79-1.62,4.74,4.74,0,0,0,.85-2.1A6.11,6.11,0,0,0,184,252.19Zm-.88.27a5,5,0,0,1,.22,2.39,3.94,3.94,0,0,1-.82,1.82A5.44,5.44,0,0,1,181,258a9.5,9.5,0,0,1-2.08.88l-2.72.83-2.84-9.39,2.72-.82a9.32,9.32,0,0,1,2.21-.41,5.46,5.46,0,0,1,2,.22,3.93,3.93,0,0,1,1.68,1.06A5,5,0,0,1,183.13,252.46Zm-5,4,.29-.09a2.61,2.61,0,0,0,1.7-1.18,2.45,2.45,0,0,0,.07-1.88,2.52,2.52,0,0,0-1.1-1.53,2.65,2.65,0,0,0-2.08,0l-.28.09Z"/>
                  <path className="cls-1" d="M190.47,240.79l6-3.54,3.77,6.39,3.35-2-3.77-6.39,6-3.55,11.07,18.78-6,3.55-4.1-7-3.35,2,4.1,7-6,3.53Zm13.29,14.44-4.2-7.13,8.28-4.88,4.2,7.13.73-.43-8.41-14.28-.73.43,3.82,6.48-8.28,4.87L195.35,241l-.73.43L203,255.66Z"/>
                  <path className="cls-1" d="M211.39,227.22l9.37-8.79,3.91,4.17-4.29,4,1.66,1.77,4.22-4,3.71,3.95-4.22,4,1.73,1.84,4.45-4.18,3.92,4.17-9.54,8.94Zm20.9,6.69-.53-.57L227,237.82l-4.9-5.22,4.53-4.25-.54-.57L221.55,232l-4.83-5.14,4.77-4.48-.54-.57-5.38,5.06L226.91,239Z"/>
                  <path className="cls-1" d="M225.63,213.52l3.13-4.83,10.12-.22-3.95-9.3,3.13-4.82,18.3,11.86-3.79,5.84L246,207.78l3.06,7.29-7.92.2,6.58,4.26-3.78,5.85Zm18.34,7-12.58-8.16,12.92-.35-5-11.91,12.59,8.16.46-.71-13.9-9-.46.72,5,12-13,.29-.46.72,13.9,9Z"/>
                  <path className="cls-1" d="M240.59,187.79l4.19-12.14,5.41,1.87-1.92,5.56,2.28.79,1.89-5.47,5.13,1.78-1.89,5.46,2.38.83,2-5.78,5.4,1.87-4.27,12.35ZM262.2,184l-.74-.26-2.14,6.18-6.76-2.34,2-5.87-.75-.25-2,5.87L245.14,185l2.14-6.18-.74-.25-2.42,7L259.79,191Z"/>
                  <path className="cls-1" d="M246.56,168.81l.87-7.47a12.73,12.73,0,0,1,1-3.78,8.79,8.79,0,0,1,2-2.88,7.39,7.39,0,0,1,2.88-1.72,8.49,8.49,0,0,1,3.71-.31,7.36,7.36,0,0,1,2.7.77,8.94,8.94,0,0,1,1.77,1.23,7.2,7.2,0,0,1,1.26,1.63l7.61-3.23-.94,8.12-5.87,2.6,5.49.64-.81,6.92Zm14.91-5.11a11,11,0,0,0,0-2.5l5.56-2.48.12-1-5.84,2.62a6.13,6.13,0,0,0-1.57-3,5.15,5.15,0,0,0-3.21-1.42,5.36,5.36,0,0,0-2.56.26,5.25,5.25,0,0,0-2,1.3,6.79,6.79,0,0,0-1.37,2.13,10.21,10.21,0,0,0-.7,2.78l-.41,3.5L266,167.78l.1-.85-4.93-.57Zm-1.09,2.57-10-1.16.31-2.66a7.43,7.43,0,0,1,1.84-4.48,4.94,4.94,0,0,1,7.38.86,7.47,7.47,0,0,1,.75,4.78ZM258,163.13l0-.36a3.76,3.76,0,0,0-.3-2.15,2.06,2.06,0,0,0-1.75-1.08,2.09,2.09,0,0,0-2,.64,3.85,3.85,0,0,0-.79,2l0,.36Z"/>
                  <path className="cls-1" d="M248.19,144.82l-.56-7.37,21.74-1.65.56,7.36ZM267,139.25l-16.53,1.26.07.85,16.52-1.26Z"/>
                  <path className="cls-1" d="M261.37,126.93a11.5,11.5,0,0,0,0-1.55,12.17,12.17,0,0,0-.1-1.51,13.71,13.71,0,0,0-.32-1.65c-.24-1-.72-1.37-1.44-1.19a1.24,1.24,0,0,0-.73.65c-.21.37-.43.82-.67,1.36s-.5,1.13-.79,1.75a11.24,11.24,0,0,1-1.1,1.83,7.58,7.58,0,0,1-1.57,1.58,6,6,0,0,1-2.25,1,6.27,6.27,0,0,1-2.77.12,5.39,5.39,0,0,1-2.27-1,6.93,6.93,0,0,1-1.72-2,9.39,9.39,0,0,1-1.08-2.64,13.51,13.51,0,0,1-.3-1.54c-.07-.52-.14-1-.2-1.4s-.1-1-.15-1.41l5.93-1.48c0,.17.05.37.08.58s0,.37.08.59.08.44.13.67.08.29.14.48a4,4,0,0,0,.21.52,1.24,1.24,0,0,0,.34.38.55.55,0,0,0,.49.08c.17,0,.34-.23.52-.58s.4-.78.63-1.27.51-1.05.83-1.66a9.42,9.42,0,0,1,1.19-1.74,8.49,8.49,0,0,1,1.67-1.53,6.54,6.54,0,0,1,2.3-1,8,8,0,0,1,2.73-.21,5.74,5.74,0,0,1,2.47.85,6.66,6.66,0,0,1,2,2,10.47,10.47,0,0,1,1.39,3.32,22.68,22.68,0,0,1,.45,2.27q.13,1,.18,1.89a15.41,15.41,0,0,1,0,1.77Zm3.14-3.62c0-.31,0-.64-.08-1s-.07-.62-.12-1-.12-.67-.2-1a7.57,7.57,0,0,0-.64-1.68,4.48,4.48,0,0,0-1.06-1.33,3.52,3.52,0,0,0-1.49-.76,4,4,0,0,0-1.92.06,3.69,3.69,0,0,0-1.63.89,8.79,8.79,0,0,0-1.29,1.57A19.31,19.31,0,0,0,255,121c-.33.65-.67,1.28-1,1.87a8.7,8.7,0,0,1-1.14,1.53,2.71,2.71,0,0,1-1.36.83,2.26,2.26,0,0,1-1.94-.29,3.78,3.78,0,0,1-1.2-2.19l-.12-.53c0-.18-.06-.36-.08-.54a4.54,4.54,0,0,0-.09-.51l-.83.2a3.3,3.3,0,0,1,.09.49c0,.15,0,.32.07.5s.08.37.13.56a5,5,0,0,0,1.48,2.63,2.82,2.82,0,0,0,2.7.5,3.38,3.38,0,0,0,1.55-.87,9,9,0,0,0,1.23-1.56,21.27,21.27,0,0,0,1.06-1.88c.33-.65.68-1.28,1-1.87a7.94,7.94,0,0,1,1.19-1.54,3.08,3.08,0,0,1,1.47-.86,2.85,2.85,0,0,1,2.57.45,4.72,4.72,0,0,1,1.5,2.64,11.34,11.34,0,0,1,.33,2c0,.33.06.65.07,1Z"/>
                  <path className="cls-1" d="M27.88,110.79A3.65,3.65,0,0,0,26.81,112a5.2,5.2,0,0,0-.66,1.52,4.09,4.09,0,0,0-.08,1.66,3.75,3.75,0,0,0,.55,1.46,4.25,4.25,0,0,0,1.12,1.16,4.76,4.76,0,0,0,1.6.72,4.38,4.38,0,0,0,1.76.09,3.9,3.9,0,0,0,1.55-.56,4.12,4.12,0,0,0,1.21-1.11,4.33,4.33,0,0,0,.72-1.55,4.2,4.2,0,0,0,0-2,4.68,4.68,0,0,0-.82-1.73l-.46,1.79-4.18-1.06,1.94-7.68,6.63,1.68A10.71,10.71,0,0,1,41.19,114a11.34,11.34,0,0,1-.33,2.94,10.71,10.71,0,0,1-13.1,7.82,11.11,11.11,0,0,1-3.87-1.81,10.8,10.8,0,0,1-2.8-3,10.38,10.38,0,0,1-1.42-3.8,10.13,10.13,0,0,1,.19-4.23,11.8,11.8,0,0,1,1.06-2.81,12,12,0,0,1,1.27-1.88,8.08,8.08,0,0,1,1.46-1.38Zm-3.78-1.27a4.7,4.7,0,0,0-.52.79,10.1,10.1,0,0,0-.46.93,8.88,8.88,0,0,0-.4,1.23,7.83,7.83,0,0,0-.18,3.11,7.54,7.54,0,0,0,1,2.78,7.62,7.62,0,0,0,2,2.21,8.46,8.46,0,0,0,3,1.37,8,8,0,0,0,3.25.18,7.4,7.4,0,0,0,2.83-1,8,8,0,0,0,2.18-2,8.18,8.18,0,0,0,1.32-2.83,7.56,7.56,0,0,0,0-4,8.28,8.28,0,0,0-1.9-3.38l-3.91-1-.8,3.16.71.18.61-2.39,3,.76A7,7,0,0,1,37.27,116a7.27,7.27,0,0,1-1.17,2.55,6.71,6.71,0,0,1-4.51,2.76,7.34,7.34,0,0,1-2.93-.17A7.65,7.65,0,0,1,26,119.93a6.58,6.58,0,0,1-2.69-4.48,7.35,7.35,0,0,1,.18-2.78,5.48,5.48,0,0,1,.35-1c.14-.31.27-.57.39-.79s.3-.5.44-.72Z"/>
                  <path className="cls-1" d="M28.45,88.81l3.41-6.22a12.44,12.44,0,0,1,2.21-3,8.56,8.56,0,0,1,2.76-1.81A6.88,6.88,0,0,1,40,77.35a8,8,0,0,1,3.35,1A7.12,7.12,0,0,1,45.39,80a8.17,8.17,0,0,1,1.11,1.7A6.46,6.46,0,0,1,47,83.6l7.8-.12-3.7,6.76-6,.19,4.56,2.51L46.48,98.7Zm14.84.82a11.09,11.09,0,0,0,.91-2.17l5.73-.19.46-.84-6,.21a5.76,5.76,0,0,0-.31-3.19,4.82,4.82,0,0,0-2.29-2.38,5.09,5.09,0,0,0-2.34-.69,5,5,0,0,0-2.19.44,6.73,6.73,0,0,0-2,1.37,10.15,10.15,0,0,0-1.6,2.18l-1.6,2.92L45.8,94.81l.38-.71-4.11-2.25ZM41.43,91.5,33.13,87l1.21-2.22a7,7,0,0,1,3.2-3.26,4.19,4.19,0,0,1,3.84.3,4.13,4.13,0,0,1,2.3,3.07,7,7,0,0,1-1,4.44Zm-1-3.59.16-.29a3.54,3.54,0,0,0,.5-2,2.3,2.3,0,0,0-3.08-1.69,3.66,3.66,0,0,0-1.42,1.49l-.16.3Z"/>
                  <path className="cls-1" d="M50.06,56.88l3.39-3.59,20.3,8.45-4.44,4.7-1.81-.77L63.22,70.2,64.1,72l-4.44,4.71ZM61,71.63l-.72-1.48,7-7.4,1.52.63.6-.62L54,56.35l-.81.86,7.28,15.05ZM54.08,57.29l12.47,5.16-6.6,7ZM63,63.81l-3.58-1.46,1.67,3.48Z"/>
                  <path className="cls-1" d="M69.43,39.1,74,36.21l9,3.66-4.24-6.69,5.44-3.45,11,17.37L90.64,50l-9-3.65L85.87,53l-5.44,3.45ZM82.21,52.42l-7.62-12L90.8,47l.68-.43-8.36-13.2-.68.43,7.62,12L73.85,39.22l-.68.43,8.36,13.2Z"/>
                  <path className="cls-1" d="M104.44,42.78,98.12,23.21l5.43-1.75a18.44,18.44,0,0,1,5.11-1,10.31,10.31,0,0,1,4.24.67,8.27,8.27,0,0,1,3.24,2.34,11.17,11.17,0,0,1,2.15,4,11.35,11.35,0,0,1,.59,4.49,8.34,8.34,0,0,1-1.27,3.79,10.47,10.47,0,0,1-3,3A18.38,18.38,0,0,1,109.86,41Zm2.17-3.3,2.5-.8q4.27-1.38,5.84-4a7.39,7.39,0,0,0,.41-6.21,7.34,7.34,0,0,0-4-4.78c-1.87-.82-4.24-.77-7.09.15l-2.5.81Zm.54-.95L102.8,25.07l1.73-.56a9.39,9.39,0,0,1,6.34-.18,6.42,6.42,0,0,1,3.69,4.36,6.44,6.44,0,0,1-.44,5.71A9.31,9.31,0,0,1,108.88,38Zm1.74-3.34A5.32,5.32,0,0,0,112,33.05a4.18,4.18,0,0,0,.18-3.57,4.15,4.15,0,0,0-2.23-2.79,5.34,5.34,0,0,0-3.73.08Z"/>
                  <polygon className="star left cls-4" points="97.42 149.47 101.06 150.9 97.36 152.37 95.71 165.31 94.46 152.38 90.82 150.94 94.53 149.48 96.17 137.15 97.42 149.47"/>
                  <polygon className="star center cls-4" points="147.13 200.28 152.22 202.29 147.04 204.33 144.74 222.41 142.99 204.34 137.91 202.34 143.08 200.29 145.37 183.07 147.13 200.28"/>
                  <path className="cls-3" d="M186.09,96.47a36.91,36.91,0,0,1,11.52,1.81,19.57,19.57,0,0,1,6.3,3.37,13.33,13.33,0,0,1,4.85,8.7,21.43,21.43,0,0,1-.42,7.57,43.56,43.56,0,0,1-3.43,9.53,112,112,0,0,1-6,10.57,86.11,86.11,0,0,1-6.06,8.74,24.26,24.26,0,0,1-4.25,4.34,10.58,10.58,0,0,1-1.7,1,2.81,2.81,0,0,1-2.39,0,9.53,9.53,0,0,1-2.69-1.84,34.48,34.48,0,0,1-4.4-5,124.45,124.45,0,0,1-8-12.46,64.26,64.26,0,0,1-4.78-9.88,31.76,31.76,0,0,1-2-8.14,16.53,16.53,0,0,1,.86-7.54,13.65,13.65,0,0,1,5.64-6.72,24.55,24.55,0,0,1,8.29-3.18A41.85,41.85,0,0,1,186.09,96.47Z"/>
                  <path className="cls-1" d="M185.81,117.36c2,0,3.77,0,5.53,0s2.06.62,1.44,2.15q-3.34,8.33-6.82,16.6c-.22.53-.92.86-1.39,1.28-.34-.61-.93-1.21-.95-1.83-.1-3-.05-5.93-.05-9.17-1.84,0-3.58,0-5.32,0s-2.23-.6-1.52-2.35c2.19-5.36,4.37-10.73,6.63-16.06.27-.64,1-1.08,1.55-1.6a6.14,6.14,0,0,1,.86,2C185.87,111.31,185.81,114.21,185.81,117.36Z"/>
                  <path className="cls-1" d="M173.06,114.75h-2.52a1.09,1.09,0,0,1-.1-.52,1.26,1.26,0,0,1,.4-.94,10.85,10.85,0,0,1,1.09-.93c.45-.36.68-.68.68-1a.56.56,0,0,0-.22-.51.94.94,0,0,0-.55-.15,2.19,2.19,0,0,0-.46,0l-.14.23-.22.06a.34.34,0,0,1-.29,0,.65.65,0,0,1-.19-.28.78.78,0,0,1-.08-.33c0-.05,0-.09,0-.11a3.11,3.11,0,0,1,.5-.1,6.69,6.69,0,0,1,1.07-.07,2.5,2.5,0,0,1,1.23.26,1,1,0,0,1,.44.9,1.41,1.41,0,0,1-.23.78,3.85,3.85,0,0,1-1.06.94c-.55.38-.82.77-.79,1.17h.11a4,4,0,0,0,1.79-.36,1,1,0,0,1,.18.54.31.31,0,0,1-.14.29A1.14,1.14,0,0,1,173.06,114.75Z"/>
                  <path className="cls-1" d="M176.31,114.82a1.84,1.84,0,0,1-1.29-.42,2.16,2.16,0,0,1-.46-1.62,3,3,0,0,1,.54-2,1.92,1.92,0,0,1,1.57-.64,1.84,1.84,0,0,1,1.29.42,2.2,2.2,0,0,1,.46,1.64,3,3,0,0,1-.54,2A1.92,1.92,0,0,1,176.31,114.82Zm.15-4a.61.61,0,0,0-.55.31,2.47,2.47,0,0,0-.2,1.23,3.45,3.45,0,0,0,.21,1.5.67.67,0,0,0,.61.35.61.61,0,0,0,.56-.31,2.73,2.73,0,0,0,.18-1.21,3.52,3.52,0,0,0-.21-1.5A.67.67,0,0,0,176.46,110.79Z"/>
                  <path className="cls-1" d="M192.47,133c0-.28,0-1.1,0-2.46,0-.26,0-.44,0-.54a.89.89,0,0,0,0-.23.24.24,0,0,0-.13-.11,1.12,1.12,0,0,0-.32,0h-.28a.42.42,0,0,1-.06-.21c0-.17.19-.29.58-.37a4.21,4.21,0,0,1,.87-.13.58.58,0,0,1,.27,0,.21.21,0,0,1,.12.14,1,1,0,0,1,0,.29v1.39c0,1.2,0,1.94,0,2.22h.81a.51.51,0,0,1,.06.25.23.23,0,0,1-.05.16.29.29,0,0,1-.19.05H191.7a.7.7,0,0,1-.06-.27c0-.07,0-.11.05-.14a.2.2,0,0,1,.14-.05Z"/>
                  <path className="cls-1" d="M198.05,133.47h-2.52a1.17,1.17,0,0,1-.1-.53,1.28,1.28,0,0,1,.4-.94,10.85,10.85,0,0,1,1.09-.93c.45-.35.68-.67.68-1a.59.59,0,0,0-.22-.51.94.94,0,0,0-.55-.15,2.19,2.19,0,0,0-.46,0,.93.93,0,0,1-.14.23l-.22.07a.31.31,0,0,1-.29,0,.67.67,0,0,1-.19-.27.78.78,0,0,1-.08-.33c0-.06,0-.1.05-.12a2.58,2.58,0,0,1,.5-.09,6.69,6.69,0,0,1,1.07-.07,2.51,2.51,0,0,1,1.23.25,1,1,0,0,1,.44.9,1.37,1.37,0,0,1-.23.78,3.85,3.85,0,0,1-1.06.95c-.55.38-.82.77-.79,1.16h.11a4,4,0,0,0,1.79-.36,1,1,0,0,1,.18.54.34.34,0,0,1-.14.3A1.28,1.28,0,0,1,198.05,133.47Z"/>
                  <polygon className="star near-right cls-4" points="188.17 182.18 190.08 182.94 188.13 183.7 187.27 190.5 186.61 183.71 184.7 182.95 186.65 182.19 187.51 175.72 188.17 182.18"/>
                  <polygon className="star far-right cls-4" points="211.21 169.34 215 170.84 211.14 172.36 209.42 185.83 208.13 172.37 204.33 170.87 208.19 169.35 209.9 156.51 211.21 169.34"/>
                  <polygon className="cls-4" points="165.26 72.24 143.67 72.49 150.17 78.57 165.26 72.24"/>
                  <polygon className="cls-3" points="122.58 72.95 143.67 72.49 137.94 78.63 122.58 72.95"/>
            </svg>
      </div>
  )
}

export default GELogo;